import type { PostBanner } from '~/types'

export const BlogPostBanner = ({
  src,
  alt,
  credit,
  creditUrl,
  noPadding,
  loading = 'lazy',
}: PostBanner) => (
  <figure>
    <div className={`image-frame${noPadding ? ' nopadding' : ''}`}>
      <img loading={loading} src={src} alt={alt} />
    </div>
    {credit && (
      <figcaption>
        Image credit:{' '}
        {creditUrl ? (
          <a target="_blank" rel="noreferrer nofollow" href={creditUrl}>
            {credit}
          </a>
        ) : (
          credit
        )}
      </figcaption>
    )}
  </figure>
)
